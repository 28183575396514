import { useState } from "react";
import TransLogo from "./TransLogo";

const SendProfile = params => {

    const [mymUsername,setMymUsername] = useState("");
    const [email,setEmail] = useState("");
    const [loading,setLoading] = useState(false);
    const [errTxt,setErrTxt] = useState("");
    const [success,setSuccess] = useState(false)

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const submitForm = async e => {
        e.preventDefault();
        setSuccess(false);
        setErrTxt("");
        if(email.length == 0 || mymUsername.length == 0){
            setErrTxt("Please fill out all fields")
            return;
        }
        if(!isValidEmail(email)){
            setErrTxt("Please input a valid email");
            return;
        }
        setLoading(true);
        let result = await params.handleForm(mymUsername,email,params.formType)
        setLoading(false);
        setSuccess(result);
        if(!result){
            setErrTxt("unexpected error!")
        }
    }

    return (
        <div className="blur" style={{display:params.show ? "flex" : "none"}} onClick={e => params.closeFunction(e)}>
            <form className="modal" onSubmit={e => submitForm(e)}>
                <img alt="MymFinder logo" loading="lazy" title="MymFinder logo" src="/images/transparentLogo.png" className="modalLogo"/>
                <p className="modalText">The Mym search engine - Find Mym profiles by key words</p>
                <div className="row"><p className="inputP">{params.nameForm}</p><input type="text" className="mymInput" placeholder="Mym username" value={mymUsername} onChange={e => setMymUsername(e.target.value)}></input></div>
                <div className="row"><p className="inputP">Contact Email</p><input type="email" className="mymInput" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input></div>
                {loading 
                    ? <h3>Loading...</h3>
                    : <div className="column">
                        <button className="mymButton">Send Profile</button>
                        <p className="cl" style={{width:"100%",textAlign:"center"}} onClick={e => params.closeFunction(e)}>Close</p>
                    </div> 
                }
                {success ? <h3>Form sent successfuly!</h3> : null}
                <p style={{color:"red"}}>{errTxt}</p>
            </form>
        </div>
    )
}

export default SendProfile;