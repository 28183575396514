import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Card, CardContent, Container, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { countries } from 'country-data';
import MenuItem from '@mui/material/MenuItem';
import { last, set } from 'lodash';
import { getAPIWithHeader, patchAPIWithHeader } from '../api/api';
const stripePromise = loadStripe('pk_live_51OPV5vG61PRS1onr0UQLLkmjIeznbwpSN0Ab54Q26szsHds1iWkIbh1wQLBewfWLe4RVhQUWVwScKFnCeCVPmlba003UGmZSbg'); // Replace with your actual Stripe public key

const CardForm = () => {
const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [paymentExists, setPaymentExists] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const fields = [
    { label: "First Name", value: firstName, setter: setFirstName, grid: { xs: 12, sm: 6 }, required: true },
    { label: "Last Name", value: lastName, setter: setLastName, grid: { xs: 12, sm: 6 }, required: true },
    { label: "Street Address", value: line1, setter: setLine1, grid: { xs: 12 }, required: true },
    { label: "Apartment, suite, etc.", value: line2, setter: setLine2, grid: { xs: 12 }, required: false },
    { label: "City", value: city, setter: setCity, grid: { xs: 12, sm: 6 }, required: true },
    { label: "State", value: state, setter: setState, grid: { xs: 12, sm: 6 }, required: false },
    { label: "Postal Code", value: postalCode, setter: setPostalCode, grid: { xs: 12, sm: 6 }, required: true },
  ];
  useEffect(() => { 
    async function fetchPaymentMethod() {
    // Set paymentExists to true IF YOOU ALREADY HAVE PAYMENT METHOD DATA
    // Placeholder for fetching payment method data
    const res = await getAPIWithHeader('/user/currentuser');
    setPaymentExists(res.data.payment_method_added);
    if (res.data.payment_method_added) {
        setFirstName(res.data.invoice.firstName);
        setLastName(res.data.invoice.lastName);
        setCity(res.data.invoice.city);
        setState(res.data.invoice.state);
        setPostalCode(res.data.invoice.postal_code);
        setCountry(res.data.invoice.country);
        setLine1(res.data.invoice.line1);
        setLine2(res.data.invoice.line2);
    }
  }
  fetchPaymentMethod();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("in handle submit");

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { 
        name: `${firstName} ${lastName}`,
        address: {
          line1: line1 ,
          line2: line2 || '_',
          city: city,
          state: state || '_',
          postal_code: postalCode,
          country: country,
        },
      },
      
    });

    if (error) {
      setCardError(error.message);
    } else {
      setCardError('');
      const body = { 
      payment_method_added: true,
      payment_method : paymentMethod.id,
      invoice : {
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        city: city,
        state: state || '_',
        postal_code: postalCode,
        country: country,
        line1: line1,
        line2: line2 || '_',
        }
      };
      const res = await patchAPIWithHeader('/user/update',body);
      if (res.success) {
        setPaymentExists(true);
      }
      console.log('[PaymentMethod]', paymentMethod);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <Grid container spacing={3}>
    {fields.map(({ label, value, setter, grid, required }, index) => (
  <Grid item key={index} {...grid}>
    <TextField
      label={label}
      value={value}
      onChange={(e) => setter(e.target.value)}
      fullWidth
      disabled={paymentExists}
      required={required}
    />
  </Grid>
))}
      <Grid item xs={12} sm={6}>
      <TextField
  select
  label="Country"
  value={country}
  onChange={(e) => setCountry(e.target.value)}
  fullWidth
  required={true}
  disabled={paymentExists}
>
  {countries.all
    .filter(({ status }) => status === 'assigned')
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ alpha2, name }) => (
      <MenuItem key={alpha2} value={alpha2}>
        {name}
      </MenuItem>
    ))}
</TextField>
      </Grid>
      <Grid item xs={12}>
  {!paymentExists && <CardElement />}
</Grid>
      {cardError && (
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{cardError}</p>
        </Grid>
      )}
      <Grid item xs={12}>
        {paymentExists ? (
          <Button type="submit" disabled={false} onClick={() => setPaymentExists(false)} style={{ backgroundColor: '#36b6ff', color: 'white' }}> 
            Update Payment Method
          </Button>
        ) : (
          <Button type="submit" disabled={false} style={{ backgroundColor: '#36b6ff', color: 'white' }}>
            Save Payment Method
          </Button>
        )}
      </Grid>
    </Grid>
  </form>
  );
};

const PaymentForm = () => {
  return (
    <Container>
   <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px', color: '#36b6ff' }}>
  Payment Information
</Typography>
      <Card>
        <CardContent>
          <Elements stripe={stripePromise}>
            <CardForm />
          </Elements>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PaymentForm;
