import { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import SearchResult from "../components/SearchResult";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import axiosRetry from "axios-retry";
import { BASE_SERVER_URL } from "../data/urls";
import ScrollToLoad from "../components/ScrollToLoad";
import FilterBtn from "../components/FilterBtn";
import CustomDropdown from "../components/CustomDropdown";
import { Helmet } from "react-helmet";
import { option } from "../data/options";
import BreadCrumbs from "../components/BreadCrumbs";
axiosRetry(axios, { 
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay
 });
const SearchResults = () => {
    
    const [query,setQuery] = useState(-1);
    const [searchResults,setSearchResults] = useState([]);
    const [currentPage,setCurrentPage] = useState(0);
    const [errorText,setErrorText] = useState("");
    const [currentQuery,setCurrentQuery] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [allFound,setAllFound] = useState(1);
    const [lastupdate,setLastUpdate] = useState("");
    const [filter,setFilter] = useState(-1);
    const searchParams = useParams();
    const location = useLocation();

    const search = useLocation().search;
    const navigate = useNavigate();
    
    const [firstRequest,setFirstRequest] = useState(true);

      
    const fetchQueryResults = async () => {
        if(true && (currentPage < allFound || currentPage == 0)){
            if(currentPage == 0){
                setSearchResults([])
            }
            setIsLoading(true);
            let searchRes;
            setErrorText("");
            try{
                let reqBod = {
                    method:"post",
                    url:`${BASE_SERVER_URL}/search`,
                    data:{
                        searchQuery:query,
                        filter:filter,
                        page:currentPage
                    },
                    withCredentials:true,
                    headers:{
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                };
                // console.log(reqBod)
                searchRes = await axios.request(reqBod);
            }catch(e){
                console.log(e);
                setErrorText("An error has occured!");
                setIsLoading(false);
                return;
            }
            setAllFound(searchRes.data.total);
            setLastUpdate(searchRes.data.lastUpdate);
            // console.log(searchRes.data.results)
            // console.log('RANKINGS:');
            // searchRes.data.results.forEach(el => {
            //     console.log(el.name,el.ranking)
            // })
            if(currentPage == 0){
                setSearchResults(searchRes.data.results)
            }else{
                let tempArr = [...searchResults];
                for(let i = 0; i < searchRes.data.results.length; i++){
                    tempArr.push(searchRes.data.results[i]);
                }
                setSearchResults(tempArr);
                
            }
            setIsLoading(false);
        }
 
    }
    const paginate = async sr => {
        if(searchResults.length > 0){
            setCurrentPage(last => {
                if(last+10 > searchResults.length){
                    return last;
                }
                if(last < 60){
                    return last+10;
                }else{
                    return last;
                }
            })
        }
    }
    const applyFilter = (active,filter) => {
        if(!active){
            setFilter(filter);
            // window.history.replaceState(null, "",`/results?query=${query}&filter=${filter}`)
            window.history.replaceState(null, "",`/${filter}/${query}`)
        }else{
            console.log('here');
            
            setFilter("");
            // window.history.replaceState(null, "",`/results?query=${query}&filter=`)
            window.history.replaceState(null, "",`/accounts/${query}`)
        }
    }


    const updateUrl = () => {
        let navTo = "";
        if(filter){
            // navTo = `/results?query=${query}&filter=${filter}`;
            navTo = `/${filter}/${query}`;
        }else{
            console.log('here');
            
            navTo = `/accounts/${query}`;
        }
        window.history.replaceState(null, "",navTo)
    }
    // useEffect(() => {
    //     fetchQueryResults(query,0)
    // },[filter])
    const handleSelect = el => {
        setQuery(el.toLowerCase());
    }

    useEffect(() => {
        // let sp = new URLSearchParams(search);
        if(searchParams.query == undefined){
            setQuery("")
        }else{
            setQuery(searchParams.query)   
        }
        console.log(searchParams.filter.toLowerCase())
        if(searchParams.filter.toLowerCase() == "new" || searchParams.filter.toLowerCase() == "free" || searchParams.filter.toLowerCase() == "top"){
            setFilter(searchParams.filter)
        }else{
            setFilter("accounts")
        }
    },[]);

    useEffect(last => {
        console.log("sentHere")
        if(query != -1 && filter != -1 && last != filter){
            if(currentPage == 0){
                fetchQueryResults();
                setFirstRequest(false)
            }else{
                
                setCurrentPage(0);
            }
            updateUrl();
        }
    },[filter,query])

    useEffect(() => {
        console.log("notHere")

        if(currentPage == 0){
            document.querySelector(".searchResults").scrollTo({
                top: 0,
                behavior:'instant'
            });
        }
        if(query != -1 && filter != -1){
            fetchQueryResults();
        }
    },[currentPage]);

    let keyWordOptions = option.map(el => el.toLowerCase());
    function capitalizeFirstLetter(string) {
        try{
            return string.charAt(0).toUpperCase() + string.slice(1);
        }catch{
            return string
        }
    }

    function filterClick(){
        setQuery("");
    }
    
    return (<div className="searchResults">
        
        <Helmet>
            <link rel="canonical" href={`${window.location.href}`}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`Best${filter && filter != 1 && filter != "accounts" ? ` ${capitalizeFirstLetter(filter)}` : ""}${query && query != -1 ? keyWordOptions.includes(query.toLowerCase()) ? ` ${query.toLowerCase()}` : "" : ""} Mym Accounts profiles of 2023`} />
            <meta property="og:description" content={`Find the best ${query && query != -1 ? keyWordOptions.includes(query.toLowerCase()) ? query.toLowerCase() : "" : ""} Mym accounts profiles ranked by likes, posts, location and more. Over 1 million profiles are reviewed and updated daily.`}/>
            <meta property="og:image" content="/images/transparentLogo.png" />
            <meta property="og:url" content={`${window.location.href}`} />
            <title>Best{`${filter && filter != 1 && filter != "accounts" ? ` ${capitalizeFirstLetter(filter)}` : ""}`}{`${query && query != -1 ? keyWordOptions.includes(query.toLowerCase()) ? ` ${query.toLowerCase()}` : "" : ""}`} Mym Accounts profiles of 2023</title>
            <meta name="description" content={`Find the best${filter && filter != 1 && filter != "accounts" ? ` ${capitalizeFirstLetter(filter)}` : ""}${query && query != -1 ? keyWordOptions.includes(query.toLowerCase()) ? ` ${query.toLowerCase()}` : "" : ""} Mym accounts profiles ranked by likes, posts, location and more. Over 1 million profiles are reviewed and updated daily.`}></meta>
        </Helmet>
        <div className="allSearchCont">
            <div>
                <div className="searchBarCont">
                    <img alt="MymFinder little logo" loading="lazy" title="MymFinder little logo" style={{cursor:"pointer"}} src="/images/mLogoTransparent.png" onClick={() => {
                        navigate(`/`,{replace:true})
                    }}/>
                    <div>
                        <div>
                            <SearchBar query={query} setQuery={setQuery} searchFunction={e => {
                                e.preventDefault();
                            }}/>
                        </div>
                        <div className="row filterRow">
                            {/* <div style={{width:"12%"}} className="filterMargin"></div> */}
                            <FilterBtn clickFunction={applyFilter} active={filter ? filter.toString().toLowerCase() == "top" : false} filter="Top" query={query}/>
                            <FilterBtn clickFunction={applyFilter} active={filter ? filter.toString().toLowerCase() == "new" : false} filter="New" query={query}/>
                            <CustomDropdown handleSelect={handleSelect}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="metaCont">
            <div className="searchMeta">
                <p>Found {allFound} results</p>
                <p>Last Update: {lastupdate}</p>
            </div>
            <BreadCrumbs first={filter} second={query} fClick={filterClick}/>
            <h1 className="bestText">🥇Best {query} Mym Accounts Profiles of 2023</h1>

        </div>
        <h1>{errorText}</h1>
        <div className="results">
            {
                searchResults.map((el,index) => {
                    return <SearchResult element={el} key={index}/>;
                })
            }
        </div>
        <h1 style={{display:isLoading ? "block" : "none", width:"100%",textAlign:"center"}}>Loading...</h1>
        <ScrollToLoad fetchData={paginate} sr={searchResults}/>
    </div>)
}

export default SearchResults;