import { createContext ,useContext,useState,useEffect} from "react";
import { get } from "lodash";

const AuthContext = createContext(null);
export const AuthProvider = ({children})=> {
const getUserAccessToken = () => {
  return localStorage.getItem("access_token");
}
  const [token, setToken] = useState();
  const [isLogged, setIsLogged] = useState(Boolean(getUserAccessToken()));
    const login = (token) => {
  
      const userToken = getUserAccessToken();
      setToken(userToken);
    setIsLogged(true);

    }
  const logout = () => {
    setIsLogged(false);
    setToken(null);
  }
  return (
    <AuthContext.Provider value={{token, login, logout,isLogged}}>
      {children}
    </AuthContext.Provider>
  );
}
export const  useAuth = () => {
  return useContext(AuthContext);
}