import axios from "axios";
import { useState } from "react";
import { BASE_SERVER_URL } from "../../data/urls";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireUnauth = () => {
    const location = useLocation();
    const [isAuthenticated,setIsAuthenticated] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const checkAuthenticated = async () => {
        try{
            let res = await axios.request({
                method:"post",
                url:`${BASE_SERVER_URL}/auth`,
                withCredentials:true,
            });
            setIsAuthenticated(true);
        }catch{
            setIsAuthenticated(false)
        }
        setIsLoading(false)
    }

    useState(async () => {
        checkAuthenticated();
    },[])

    return isLoading
        ? <h3>Loading...</h3>
        :   isAuthenticated
            ? <Navigate to="/upload-file" state={{from:location}} replace/>
            : <Outlet/>

}

export default RequireUnauth;