import {BrowserRouter, Routes, Route} from "react-router-dom"
import MainSearch from './pages/MainSearch';
import SearchResults from "./pages/SearchResults";
import UploadPage from "./pages/UploadPage";
import RequireUnauth from "./components/auth/RequireUnauth";
import RequireAuth from "./components/auth/RequireAuth";
import UploadCSV from "./pages/UploadCSV";
import NotFound from "./pages/NotFound";
import FilterFilter from "./components/FilterFilter";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ProfileSettings from "./pages/ProfileSettings";
import { AuthProvider } from "./components/auth";
import { RequireAuthorization } from "./components/RequireAuthorization";
import AdminPage from "./pages/Adminpage";
import DivertPage from "./pages/Divert";
//404

function App() {
  return (
    <AuthProvider>
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Routes>
            <Route path="/" element={<MainSearch/>}/>
            <Route path="/results" element={<SearchResults/>}/>
            <Route element={<FilterFilter/>}>
              <Route path="/:filter/:query?" element={<SearchResults/>}/>
            </Route>
            <Route element={<RequireUnauth/>}>
              <Route path="/login" element={<LoginPage/>}/>
              <Route path="/signup" element={<SignupPage/>}/>
              <Route path="/admin" element={<UploadPage/>}/>
              

            </Route>
            <Route path="/profile" 
            
            element={
            <RequireAuthorization>
              <ProfileSettings/>
            </RequireAuthorization>}/>
            <Route element={<RequireAuth/>}>
              <Route path="/adminpage" element={<AdminPage/>}/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route path="/divert" element={<DivertPage/>}/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route path="/upload-file" element={<UploadCSV/>}/>
            </Route>
            <Route path='/404' element={<NotFound />} status={404}/>
            <Route path='*' element={<NotFound />} status={404}/>
          </Routes>
        </div>
        </BrowserRouter>
    </div>
    </AuthProvider>
  );
}
// function App() {
//   return (
//     <div className="App">
//       <MainSearch/>
//     </div>
//   );
// }

export default App;
