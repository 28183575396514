import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const ScrollToLoad = params => {
  const targetRef = useRef(null);
  const [toLoad,setToLoad] = useState(true);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '500px', 
      threshold: 0.01,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if(toLoad){
            params.fetchData(params.sr);
            setToLoad(false)
          }
        }else{          
          setToLoad(true);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [params,toLoad]);


  return (
      <div ref={targetRef} style={{ height: '10px', background: 'transparent' }} />
  );
};

export default ScrollToLoad;
