import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { AppBar } from '@mui/material';
import { Toolbar } from '@mui/material';
import { useEffect } from 'react';
import { getAPIWithHeader, patchAPIWithHeader } from '../api/api';
const AdminPage = () => {

    // Replace this with your own data.  useeffect use kar idhr setrows ko set kar us mein iss ko array of objects chaiye in same format jo neechy likha hai

  const [rows, setRows] = useState([]);
  useEffect(() => {
    const getinitialData = async () => {
      const res = await getAPIWithHeader("/user/allusers");
      console.log("res is ", res.data);
      if (Array.isArray(res.data)) {
        setRows(res.data);
      } else {
        console.error('Error: res.data is not an array');
      }
    }
  
    getinitialData();
  }, []);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [search, setSearch] = useState('');

const handleSearchChange = (event) => {
  setSearch(event.target.value);
};

const filteredRows = rows ? rows.filter((row) => 
    row.email.includes(search) ||
    row.mymAccount.includes(search) ||
    row.firstName.includes(search) ||
    row.lastName.includes(search)
  ) : [];

  const columns = [
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mymAccount', headerName: 'mymAccount', width: 120 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    { field: 'bid_price', headerName: 'Bidding Price', width: 150 },
    { field: 'payable_amount', headerName: 'Payable Amount', width: 150 },
    { field: 'require_bid_service', headerName: 'Bidding Enabled', width: 150 },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = async (event) => {
          
            event.stopPropagation();
          const id = params.row.email;
          // idhr delete karne ka code likho or try mein setrows ko ly jao or id bhejni hai delete karne ke liyeƒ
          const res = await patchAPIWithHeader('/user/deleteadmin', {email: id});
          if (res.success){
          setRows(prevRows => prevRows.filter(row => row.email !== id));
          }
        };

        return <Button onClick={onClick}>Delete</Button>;
      },
    },
  ];

  const handleRowClick = (param) => {
    setSelectedRow(param.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // Save the changes here, you can make a PUT request to your API
    //selectedRow.email is the unique identifie
    const res = patchAPIWithHeader('/user/updateadmin', selectedRow);
    setRows(prevRows => {
        const otherRows = prevRows.filter(row => row.email !== selectedRow.email);
        return [...otherRows, selectedRow];
      });
      setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar  position='fixed' sx={{ bgcolor : "rgb(255, 255,255)"}}>
            <Toolbar>
    <img src="images/MymF.png" height="60px" width="120px" alt="Mym Finder" />
  </Toolbar>    
                       </AppBar>
                       <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <TextField
        id="search"
        label="Search"
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
      />
                       
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={filteredRows} 
        columns={columns} 
        pageSize={5} 
        getRowId={(row) => row.email} 
        onRowClick={handleRowClick}
      />
      </div>
    </Box>
      {selectedRow && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Record</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="MymAccount"
            label="Mym Name"
            value={selectedRow.mymAccount}
            onChange={(e) => setSelectedRow({ ...selectedRow, MymAccount: e.target.value })}
            required
            error={!selectedRow.mymAccount}
            helperText={!selectedRow.mymAccount ? 'Name is required' : ''}
            fullWidth
          />
          <TextField
            margin="dense"
            id="firstName"
            label="First Name"
            value={selectedRow.firstName}
            onChange={(e) => setSelectedRow({ ...selectedRow, firstName: e.target.value })}
            required
            error={!selectedRow.firstName}
            helperText={!selectedRow.firstName ? 'First name is required' : ''}
            fullWidth
          />
          <TextField
            margin="dense"
            id="lastName"
            label="Last Name"
            value={selectedRow.lastName}
            onChange={(e) => setSelectedRow({ ...selectedRow, lastName: e.target.value })}
            required
            error={!selectedRow.lastName}
            helperText={!selectedRow.lastName ? 'Last name is required' : ''}
            fullWidth
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            value={selectedRow.email}
            onChange={(e) => setSelectedRow({ ...selectedRow, email: e.target.value })}
            required
            error={!selectedRow.email || !/\S+@\S+\.\S+/.test(selectedRow.email)}
            helperText={!selectedRow.email ? 'Email is required' : (!/\S+@\S+\.\S+/.test(selectedRow.email) ? 'Email is not valid' : '')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="bid_price"
            label="Bidding Price"
            value={selectedRow.bid_price}
            onChange={(e) => setSelectedRow({ ...selectedRow, bid_price: e.target.value })}
            required
            error={!selectedRow.bid_price || isNaN(selectedRow.bid_price)}
            helperText={!selectedRow.bid_price ? 'Bidding price is required' : (isNaN(selectedRow.bid_price) ? 'Bidding price must be a number' : '')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="payable_amount"
            label="Payable Amount"
            value={selectedRow.payable_amount}
            onChange={(e) => setSelectedRow({ ...selectedRow, payable_amount: e.target.value })}
            required
            error={!selectedRow.payable_amount || isNaN(selectedRow.payable_amount)}
            helperText={!selectedRow.payable_amount ? 'Payable amount is required' : (isNaN(selectedRow.payable_amount) ? 'Payable amount must be a number' : '')}
            fullWidth
          />
          <TextField
            margin="dense"
            id="require_bid_service"
            label="Bidding Enabled"
            value={selectedRow.require_bid_service}
            onChange={(e) => setSelectedRow({ ...selectedRow, require_bid_service: e.target.value })}
            required
            error={!selectedRow.require_bid_service || typeof selectedRow.require_bid_service !== 'boolean'}
            helperText={!selectedRow.require_bid_service ? 'Bidding enabled is required' : (typeof selectedRow.require_bid_service !== 'boolean' ? 'Bidding enabled must be a boolean' : '')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      )}
    </Box>
  );
};

export default AdminPage;