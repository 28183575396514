import { useState } from "react"
import axios from "axios";
import { BASE_SERVER_URL } from "../data/urls";
import { useNavigate } from "react-router-dom";
import AdminPage from "./Adminpage";

const UploadPage = () => {

    const [password,setPassword] = useState("");
    const [error,setError] = useState("");
    const navigate = useNavigate();

    const enter = async e => {
        e.preventDefault();
        setError("")
        try{
            let res = await axios.request(
                {
                    url:`${BASE_SERVER_URL}/enter`,
                    method:"post",
                    data:{
                        pass:password
                    },
                    withCredentials:true,
                    headers:{
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
            navigate("/divert",{replace:true});
            console.log("res is true");
        }catch(e){
            if(e.response.status == 401){
                setError("Incorrect password");
                console.log("res is false");
            }else{
                setError("Unexpected error");
            }
        }
    }

    return (
        <form onSubmit={enter} className="uploadPage">
            <p>Pass:</p>
            <input type="password" onChange={e => {
                setPassword(e.target.value)
            }}/>
            <button type="submit">Enter</button>
            <p>{error}</p>
        </form>
    )
}

export default UploadPage