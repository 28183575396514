


// const BASE_SERVER_URL = "http://localhost:3001";
export const BASE_URL =  "https://test-api.mymfinder.com";

//export const BASE_URL =  "https://api.mymfinder.com";

export const postAPIWithoutHeader = async (end_point, data) => {
    console.log("postAPIWithoutHeader")

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow'
    };
    console.log(data)
    console.log(BASE_URL + end_point);
    let res = await fetch(BASE_URL + end_point, requestOptions);

    const resData = await res.json();
    console.log("res from post api is", res);
    return { "success": res.ok, 
              "status": res.status, 
              "data": resData
                  }
  } catch (err) {

    console.log("thfffffe error we get is ",err);
    return { "success": false, 
    "status": 400, 
    "data": {error: "Something went rwrong"}
        }
  }
}

export const postAPIWithHeader = async (end_point, data) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${await getUserAccessToken()}`);
    myHeaders.append("x-auth-token", `${await getUserAccessToken()}`);
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow'
    };
    console.log("postAPIWithHeader");
    let res = await fetch(BASE_URL + end_point, requestOptions);
    const resData = await res.json();
    return { "success": res.ok, 
              "status": res.status, 
              "data": resData
                  }
  } catch (err) {
    return err
  }
}

export const getAPIWithHeader = async (end_point) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${await getUserAccessToken()}`);
    myHeaders.append("x-auth-token", `${await getUserAccessToken()}`);
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };
    
    let res = await fetch(BASE_URL + end_point, requestOptions);
    const resData = await res.json();
    console.log("res", resData);
    return { "success": res.ok, 
              "status": res.status, 
              "data": resData
                  }
  } catch (err) {
    return err
  }
};

export const patchAPIWithHeader = async (end_point, data) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${await getUserAccessToken()}`);
    myHeaders.append("x-auth-token", `${await getUserAccessToken()}`);
    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };
    console.log("patchAPIWithHeader");
    let res = await fetch(BASE_URL + end_point, requestOptions);
    console.log("res", res);
    const resData = await res.json();
    return { "success": res.ok, 
              "status": res.status, 
              "data": resData
           };
  } catch (err) {
    return err;
  }
};

export const setUserAccessToken = async (access_token) => {
  return await localStorage.setItem("access_token", access_token);
};

export const getUserAccessToken = async () => {
 
    var newAccess_Token =  await localStorage.getItem("access_token");
    return newAccess_Token;
  
};

export const deleteAccessToken = async () => {
  return await localStorage.removeItem("access_token");
};