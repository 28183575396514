import { useNavigate } from "react-router-dom";

const FilterBtn = params => {

    const navigate = useNavigate();

    return (
        <div className={`filterBtn homeScreenBtn ${params.active ? "filterActive" : ""}`} onClick={() => {
            params.clickFunction(params.active,params.filter.toLowerCase());
        }}>
            <p>{params.filter}</p>
        </div>
    )
}

export default FilterBtn;