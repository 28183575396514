import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { option } from '../data/options';

const CustomDropdown = params => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach the event listener on component mount
    document.addEventListener('click', handleClickOutside);

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  let options = option;
  const navigate = useNavigate();

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-btn searchDropDown homeScreenBtn" onClick={toggleDropdown}>
            <p>Categories</p>
            <img alt='Dropdown arrow' loading="lazy" title="Dropdown arrow" src="/images/dropdown.svg"/>
      </button>
      {isOpen && (
        <div className="dropdown-content">
            {options.map((el,index) => {
                return (<p onClick={() => {
                  setIsOpen(false);
                    params.handleSelect(el);
                }} key={index}>{el}</p>)
            })}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
