import { Navigate } from 'react-router-dom';
import { useAuth } from './auth';

export const RequireAuthorization = ({ children }) => {
    const auth = useAuth();

    if (!auth.isLogged) {
        return (<Navigate to="/login" />);
    }
    return children;
}
